import React from "react";
import { Container } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";


const CustomerAlertPage = () => {

    return (
        <Container>
            <PageTitle
                pageTitle={`Customer Alert`}
                showBreadcrumb={[
                    { label: "Home", to: "/" },
                ]}
                BreadCrumbCurrentPageTitle={"Customer Alert"}
                showLastSeen={true}
            />
            <div className="sectionBox">

            </div>
        </Container>
    )
}
export default CustomerAlertPage;