import React, { useContext, useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import EmployeeStepsContext from "../../../hooks/EmployeeStepsContext";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import AuthContext from "../../../hooks/authContext";
import { getAllEmployee } from "../../../services/apiservices/employee";

const StepProfile = (props) => {
  const stepsContext = useContext(EmployeeStepsContext);
  const auth = useContext(AuthContext);
  const [companyDetails, setCompanyDetails] = useState([]);
  const { handleInputChange } = props;
  const { stepFormValues, stepFormErrors } = stepsContext;

  const selectedValues = [
    { label: "EDT - Eastern", value: "America/New_York" },
    { label: "CDT - Central", value: "America/Chicago" },
    { label: "MDT - Mountain", value: "America/Denver" },
    { label: "PDT - Pacific", value: "America/Los_Angeles" }
  ];

  const getAllEmployeeData = () => {
    getAllEmployee((res) => {
      if (res) {
        const data = res?.data?.filter((item) => item.role === "companyUser")
        const companyNames = data.map(res => ({
          value: res?.id,
          label: `${res?.firstName} ${res?.lastName}`,
          name: `${res?.firstName} ${res?.lastName}`,
          firstName: res?.firstName,
          lastName: res?.lastName,
        }));
        setCompanyDetails(companyNames)
      } else {
        setCompanyDetails([]);
      }
    });
  }

  useEffect(() => {
    getAllEmployeeData();
  }, [])

  return (
    <>
      <h5 className="stepSectionTitle">Personal Information</h5>
      <div className="stepSectionContent">
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="firstName"
            id="firstName"
            label="First Name"
            variant="outlined"
            placeholder="First Name"
            inputProps={{ maxLength: 20 }}
            onChange={handleInputChange}
            value={stepFormValues?.firstName ? stepFormValues?.firstName : ""}
          />
          {typeof stepFormErrors?.firstName !== "undefined" &&
            stepFormErrors?.firstName !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.firstName}
              </span>
            )}
        </div>
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="lastName"
            id="lastName"
            label="Last Name"
            variant="outlined"
            placeholder="Last Name"
            inputProps={{ maxLength: 20 }}
            onChange={handleInputChange}
            value={stepFormValues?.lastName ? stepFormValues?.lastName : ""}
          />
          {typeof stepFormErrors?.lastName !== "undefined" &&
            stepFormErrors?.lastName !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.lastName}
              </span>
            )}
        </div>
        <div className="formFieldwrap w-50">
          <TextField
            fullWidth={true}
            size="small"
            name="phoneNumber"
            id="phoneNumber"
            label="Mobile"
            variant="outlined"
            placeholder="Mobile"
            onChange={handleInputChange}
            value={
              stepFormValues?.phoneNumber ? stepFormValues?.phoneNumber : ""
            }
          />
          {typeof stepFormErrors?.phoneNumber !== "undefined" &&
            stepFormErrors?.phoneNumber !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.phoneNumber}
              </span>
            )}
        </div>
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="email"
            id="email"
            label="Email"
            variant="outlined"
            placeholder="Email"
            onChange={handleInputChange}
            value={stepFormValues?.email ? stepFormValues?.email : ""}
          />
          {typeof stepFormErrors?.email !== "undefined" &&
            stepFormErrors?.email !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.email}
              </span>
            )}
        </div>
        {auth.userProfile.role === "superUser" ?
          <div className="formFieldwrap">
            <SingleSelect
              label="Company *"
              variant="outlined"
              name="companyId"
              id="companyId"
              placeholder="Company"
              onChange={handleInputChange}
              value={stepFormValues?.companyId ? stepFormValues?.companyId : ""}
              options={companyDetails}
            />
            {typeof stepFormErrors?.companyId !== "undefined" &&
              stepFormErrors?.companyId !== "" && (
                <span className="d-flex small error color-danger">
                  {stepFormErrors?.companyId}
                </span>
              )}
          </div>
          : null}
        <div className="formFieldwrap">
          <SingleSelect
            fullWidth={true}
            size="small"
            name="timeZone"
            id="timeZone"
            label="TimeZone *"
            placeholder="TimeZone"
            value={stepFormValues?.timeZone}
            onChange={handleInputChange}
            options={selectedValues}
          />
          {typeof stepFormErrors?.timeZone !== "undefined" &&
            stepFormErrors?.timeZone !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.timeZone}
              </span>
            )}
        </div>
        <div className="formFieldwrap">
          <SingleSelect
            fullWidth={true}
            size="small"
            name="isManager"
            id="isManager"
            label="Is Manager"
            placeholder="Is Manager"
            value={stepFormValues?.isManager ? stepFormValues?.isManager : "false"}
            onChange={handleInputChange}
            options={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" }
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default StepProfile;
