import { Button, Container } from "@material-ui/core";
import React, { useState } from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { useMediaQuery } from "@mui/material";
import MultiSelect from "../../../components/SelectField/MultiSelect";
import styles from "../Accounts.module.scss";


const NotificationPageSetting = () => {
    const isMobile = useMediaQuery('(max-width:767px)');
    const [smsRoles, setSMSRoles] = useState([]);
    const [emailRoles, setEmailRoles] = useState([]);
    const [pushNotificationRoles, setPushNotificationRoles] = useState([]);
    const [error, setError] = useState({});

    const Roles = [
        { label: "CLIENT", value: "client" },
        { label: "EMPLOYEE", value: "employee" },
        { label: "ADMIN", value: "admin" },
        { label: "SUPPORT", value: "support" },
    ]

    const validFields = () => {
        const errormessages = {};
        let isValid = true;

        if (!smsRoles.length) {
            isValid = false;
            errormessages.smsRoles = "SMS Roles is Required";
        }
        if (!emailRoles.length) {
            isValid = false;
            errormessages.emailRoles = "Email Roles is Required";
        }
        if (!pushNotificationRoles.length) {
            isValid = false;
            errormessages.pushNotificationRoles = "Push Notification Roles is Required";
        }

        setError(errormessages);
        return isValid;
    };

    const handleSaveData = () => {
        if (!validFields()) {
            console.log("Saved data:", { smsRoles, emailRoles, pushNotificationRoles });
        }
    };

    const handleReset = () => {
        setSMSRoles([]);
        setEmailRoles([]);
        setPushNotificationRoles([]);
        setError({});
    };


    return (
        <Container>
            <PageTitle
                pageTitle="Notification Settings"
                showBreadcrumb={[
                    { label: "Home", to: "/" },
                    { label: "Settings", to: "/settings" },
                ]}
                BreadCrumbCurrentPageTitle={"Notification Settings"}
                showLastSeen={true}
            />
            <div className="sectionBox">
                <div className={`${isMobile ? "w-100" : "w-100 d-flex"}`}>
                    <div className={styles.widths}>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>SMS</div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <MultiSelect
                                    value={smsRoles}
                                    name="smsRoles"
                                    label="SMS Roles"
                                    onChange={(value) => setSMSRoles(value)}
                                    options={Roles}
                                />
                                {error.smsRoles && (
                                    <span className="d-flex small error color-danger">{error.smsRoles}</span>
                                )}
                            </div>
                        </div>

                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>EMAIL</div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <MultiSelect
                                    value={emailRoles}
                                    name="emailRoles"
                                    label="Email Roles"
                                    onChange={(value) => setEmailRoles(value)}
                                    options={Roles}
                                />
                                {error.emailRoles && (
                                    <span className="d-flex small error color-danger">{error.emailRoles}</span>
                                )}
                            </div>
                        </div>

                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>PUSH NOTIFICATION</div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <MultiSelect
                                    value={pushNotificationRoles}
                                    name="pushNotificationRoles"
                                    label="Push Notification Roles"
                                    onChange={(value) => setPushNotificationRoles(value)}
                                    options={Roles}
                                />
                                {error.pushNotificationRoles && (
                                    <span className="d-flex small error color-danger">
                                        {error.pushNotificationRoles}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mv-15">
                    <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={handleSaveData}>Save</Button>
                    <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleReset}>Cancel</Button>
                    <Button variant="contained" color="secondary" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleReset}>Reset</Button>
                </div>
            </div>
        </Container>
    )

}
export default NotificationPageSetting;