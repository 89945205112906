/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, IconButton, TextField } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import TableToolbar from "../../../components/Table/TableToolbar";
import ThemeTable from "../../../components/Table/ThemeTable";
import CommonContext from "../../../hooks/commonContext";
import EditIcon from "@material-ui/icons/Edit";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";
import { getAllEmployee } from "../../../services/apiservices/employee";
import AuthContext from "../../../hooks/authContext";


const ManageAdmin = (props) => {
    const commonContext = useContext(CommonContext);
    const history = useNavigate();
    const auth = useContext(AuthContext);
    const [allAdmins, setAllAdmins] = React.useState();
    const [searchTerm, setsearchTerm] = React.useState("");
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const [isLoading, setIsLoading] = React.useState(false);


    const Columns = [
        {
            id: "employeeName",
            numeric: false,
            disablePadding: true,
            label: "Name",
            isSortable: true,
        },
        {
            id: "email",
            numeric: false,
            disablePadding: true,
            label: "Email",
            isSortable: true,
        },
        {
            id: "contactNumber",
            numeric: false,
            disablePadding: true,
            label: "Contact",
            isSortable: true,
        },
        {
            id: "company",
            numeric: false,
            disablePadding: true,
            label: "Company",
            isSortable: true,
        },
        {
            id: "role",
            numeric: false,
            disablePadding: true,
            label: "Role",
            isSortable: true,
        },
        { id: "action", numeric: false, disablePadding: true, label: "Action", rowclassName: "text-right", className: "text-right pr-30" },
    ];

    const fetchUserList = async () => {
        setIsLoading(true);
        try {
            let data = [];
            await getAllEmployee((res) => {
                data = res?.data;
            });

            if (data) {
                const companyUsers = data.filter(item => item.role === 'companyUser');
                const admins = data.filter(item => item.role === 'admin' && !item.deleted);

                let filterEmpData = [];
                if (auth.userProfile.role !== "superUser") {
                  const Id = auth.userProfile.role === "companyUser" ? auth.userProfile.id : auth.userProfile.companyId;
                  filterEmpData = admins?.filter((item) => item.companyId === Id);
                } else {
                  filterEmpData = admins;
                }

                const setalladminData = filterEmpData.map((adminItem) => {
                    const companyName = companyUsers.find((item) => item.id === adminItem.companyId);

                    return {
                        ...adminItem,
                        employeeName: `${adminItem.firstName} ${adminItem.lastName}`,
                        contactNumber: adminItem.phoneNumber,
                        company: companyName ? `${companyName?.firstName} ${companyName?.lastName}` : null,
                    };
                });

                setAllAdmins(setalladminData);
            }
        } catch (error) {
            console.error("Error fetching user list:", error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchUserList();
    }, []);

    const redirectToCreateAdmin = (adminId) => {
        if (typeof adminId !== "undefined" && adminId !== "")
            history("/createUserAdmin/" + adminId);
        else history("/createUserAdmin");
    };
    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "email":
                return (
                    <>
                        <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${rcellItem[hcellItem?.id]}`} rel="noopener noreferrer" target="_blank" className="resLinks" style={{ overflowWrap: "anywhere" }}>
                            {rcellItem[hcellItem?.id]}
                        </a>
                    </>
                );
            case "action":
                return (
                    <>
                        <IconButton
                            aria-label="Edit"
                            color="primary"
                            size="small"
                            onClick={() => redirectToCreateAdmin(rcellItem?.id)}
                        >
                            <EditIcon />
                        </IconButton>
                    </>
                );

            default:
                return rcellItem[hcellItem?.id];
        }
    };

    const tableRightComponent = () => {
        return (
            <div className="d-flex groupsearch">
                <div className=" mh-9">
                    <TextField
                        fullWidth={true}
                        size="small"
                        name="searchTerm"
                        id="searchTerm"
                        label=""
                        variant="outlined"
                        placeholder="Search Admin..."
                        className="input"
                        onChange={(event) => {
                            setsearchTerm(event.target.value);
                        }}
                        value={searchTerm ? searchTerm : ""}
                    />
                </div>
                <Button
                    className="flatbutton w-norm"
                    size={"small"}
                    variant="contained"
                    color="primary"
                    onClick={() => redirectToCreateAdmin()}
                >
                    Add New Admin
                </Button>
            </div>
        )
    };

    const checkRowSearch = (searchTerm, row) => {
        return (
            String(row.employeeName)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.company)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.email)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase())
        );
    };
    const filteredList =
        searchTerm !== ""
            ? allAdmins?.filter((item) => checkRowSearch(searchTerm, item))
            : allAdmins;

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Manage Admin"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Admin", to: "/createUserAdmin" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Manage Admin"}
                    showLastSeen={true}
                />

                <div className={`sectionBox2`}>
                    <TableToolbar
                        title={isMobile || isLandscape ? "" : "Manage Admin"}
                        rightComponent={tableRightComponent()}
                    />

                    <ThemeTable
                        rows={filteredList}
                        headCells={Columns}
                        hidePagination={true}
                        isLoading={isLoading}
                        renderRowCell={renderRowCell}
                    />
                </div>
            </Container>
        </>
    );
};
export default ManageAdmin;
