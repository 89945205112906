import React, { useContext, useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import ConfirmCancel from "../../../components/DialogComponent/ConfirmCancel";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../components/AccordionComponent/AccordionComponent";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import validator from "validator";
import { useEffect } from "react";
import Avatar from "react-avatar-edit";
import AddressByMap from './AddressByMap';
import { ClientAddressUpdate } from "../../../components/DialogComponent/SupplyDataDialog";
import AuthContext from "../../../hooks/authContext";
import { useParams } from "react-router";
import { getClientsList } from "../../../services/apiservices/client";
import { getAllEmployee } from "../../../services/apiservices/employee";
const stepId = 1;

function isAddressMatch(address1, address2) {
  return (
      address1.city === address2.city &&
      address1.state === address2.state &&
      address1.lng === address2.lng &&
      address1.lat === address2.lat &&
      address1.address === address2.address &&
      address1.zip === address2.zip
  );
}

function Step1(props) {
  const [stepContent, setStepContent] = useState({});
  const [stepError, setStepError] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clientRes, setClientRes] = useState();
  const [companyDetails, setCompanyDetails] = useState([]);
  const { clientId } = useParams();
  const auth = useContext(AuthContext);

  const id = clientId ? clientId : auth.newClientId;

  const {
    currentStep,
    goToStep,
    onCancelAll,

    loading,
    classes,
  } = props;

  const allIndustryTypes = [
    "Retail",
    "Medical",
    "Industrial",
    "Restaurant",
    "Office",
    "Other",
  ].map((IndustyItem) => {
    return {
      label: `${IndustyItem}`,
      value: IndustyItem,
    };
  });
  const allFrequency = ["Daily", "Monthly", "Weekly"].map((frequencyItem) => {
    return {
      label: `${frequencyItem}`,
      value: frequencyItem,
    };
  });

  const selectedValues = [
    { label: "EDT - Eastern", value: "America/New_York" },
    { label: "CDT - Central", value: "America/Chicago" },
    { label: "MDT - Mountain", value: "America/Denver" },
    { label: "PDT - Pacific", value: "America/Los_Angeles" }
  ];

  const setEditData = (editData) => {
    setStepContent({
      clientName: editData?.clientName ? editData?.clientName : '',
      firstName: editData?.firstName ? editData?.firstName : '',
      lastName: editData?.clientName ? editData?.lastName : '',
      clientLogo: editData?.clientLogo ? editData?.clientLogo : '',
      industryType: editData?.industryType ? editData?.industryType : '',
      industryTypeOther: editData?.industryTypeOther ? editData?.industryTypeOther : '',
      summaryReportFrequency: editData?.summaryReportFrequency ? editData?.summaryReportFrequency : '',
      timeZone: editData?.timeZone ? editData?.timeZone : '',
      clientAddress: editData?.clientAddress ? editData?.clientAddress : '',
      clientLocation: editData?.clientLocation ? editData?.clientLocation : '',
      addressInfo: editData?.addressInfo ? editData?.addressInfo : {city: '', state:'', country: '', zip: '', address:''},
    })
  }
  useEffect(() => {
    if (props?.editData !== false) {
      setEditData(props?.editData);
    }
  }, [props?.editData])

  const getAllClients = () =>{
    getClientsList({}, (res)=>{
      if(res){
        setClientRes(res);
      } else {
        setClientRes();
      }
    })
  }

  const getAllEmployeeData = () => {
    getAllEmployee((res) => {
      if (res) {
        const data = res?.data?.filter((item) => item.role === "companyUser")
        const companyNames = data.map(res => ({
          value: res?.id,
          label: `${res?.firstName} ${res?.lastName}`,
          name: `${res?.firstName} ${res?.lastName}`,
          firstName: res?.firstName,
          lastName: res?.lastName,
        }));
        setCompanyDetails(companyNames)
      } else {
        setCompanyDetails([]);
      }
    });
  }

  useEffect(() => {
    getAllClients();
    getAllEmployeeData();
  }, [])

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setStepContent({ ...stepContent, [name]: value });
    setStepError({ ...stepError, [name]: "" });
  };

  const handelUpdateAddress = () => {
    props?.validateAndGoNext && props?.validateAndGoNext({stepContent}, { step1: true });
    setDialogOpen(false);
  };
  
  const handelCloseDailog = () => {
     setDialogOpen(false);
  }
  const validateAndGoNext = () => {
    const {
      clientName,
      industryType,
      industryTypeOther,
      summaryReportFrequency,
      firstName,
      lastName,
      timeZone,
      companyId,
      addressInfo
    } = stepContent;

    let errormessages = stepError;
    let isValid = true;
    if (
      typeof clientName === "undefined" ||
      clientName === undefined ||
      clientName === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        clientName: "Client Name is Required",
      };
    } else if (!validator.isLength(clientName, { min: 0, max: 100 })) {
      isValid = false;
      errormessages = {
        ...errormessages,
        clientName: "Length of Client Name should not grater than 15 letters",
      };
    } else {
      errormessages = { ...errormessages, firstName: "" };
    }
     if (
      typeof firstName === "undefined" ||
      firstName === undefined ||
      firstName === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        firstName: "First Name is Required",
      };
    }
     if (
      typeof lastName === "undefined" ||
      lastName === undefined ||
      lastName === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        lastName: "Last Name is Required",
      };
    }
    if (typeof industryType === "undefined" || industryType === "") {
      isValid = false;
      errormessages = {
        ...errormessages,
        industryType: "Industry Type is Required",
      };
    } else if (
      industryType === "Other" &&
      (typeof industryTypeOther === "undefined" || industryTypeOther === "")
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        industryTypeOther: "Industry Type Name is Required",
      };
    } else {
      errormessages = {
        ...errormessages,
        industryType: "",
        industryTypeOther: "",
      };
    }
    if (
      typeof summaryReportFrequency == "undefined" ||
      summaryReportFrequency === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        summaryReportFrequency: "Summary Report Frequency is Required",
      };
    } else {
      errormessages = { ...errormessages, summaryReportFrequency: "" };
    }
    if (
      typeof timeZone == "undefined" ||
      timeZone === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        timeZone: "Time Zone is Required",
      };
    } else {
      errormessages = { ...errormessages, timeZone: "" };
    }
    if(auth.userProfile.role === "superUser"){
      if (
        typeof companyId == "undefined" ||
        companyId === ""
      ) {
        isValid = false;
        errormessages = {
          ...errormessages,
          companyId: "Company is Required",
        };
      } else {
        errormessages = { ...errormessages, timeZone: "" };
      }
    }
    // Validate Address Information
    if (!addressInfo?.address || addressInfo.address.trim() === "") {
      isValid = false;
      errormessages.address = "Address is Required";
    } else {
      errormessages.address = "";
    }
    if (!addressInfo?.city || addressInfo.city.trim() === "") {
      isValid = false;
      errormessages.city = "City is Required";
    } else {
      errormessages.city = "";
    }
    if (!addressInfo?.state || addressInfo.state.trim() === "") {
      isValid = false;
      errormessages.state = "State is Required";
    } else {
      errormessages.state = "";
    }
    if (!addressInfo?.zip || addressInfo.zip.trim() === "") {
      isValid = false;
      errormessages.zip = "Zip Code is Required";
    } else {
      errormessages.zip = "";
    }
    setStepError(errormessages);
    if (isValid) {
      if (id) {
        const details = clientRes?.find((item) => item.id === id);
        const isAddressChanged = !isAddressMatch(details?.addressInfo, stepContent?.addressInfo);
        if (isAddressChanged) {
          setDialogOpen(true);
        } else {
          props?.validateAndGoNext && props.validateAndGoNext({ stepContent }, { step1: true });
        }
      } else {
        props?.validateAndGoNext && props.validateAndGoNext({ stepContent }, { step1: true });
      }
    }
  };

  const onClose = () => {
    // setPview({ preview: null });
  }

  const onCrop = (preview) => {
    handleInputChange({ target: { name: "clientLogo", value: preview } });
  }
  const clearFields = (updatedInfo, fields) => {
    fields.forEach((field) => {
      updatedInfo[field] = "";
    });
  };
  const handleManualAddressChange = (field, value) => {
    setStepContent((prevContent) => {
      const currentAddressInfo = prevContent.addressInfo || {};
  
      const updatedAddressInfo = {
        ...currentAddressInfo,
        [field]: value,
      };
  
      const isAddressChanged = currentAddressInfo.address !== updatedAddressInfo.address;
      const isCityChanged = currentAddressInfo.city !== updatedAddressInfo.city;
      const isStateChanged = currentAddressInfo.state !== updatedAddressInfo.state;
  
      if (!updatedAddressInfo.address || isAddressChanged) {
        clearFields(updatedAddressInfo, ["city", "state", "zip", "lat", "lng"]);
      } else if (!updatedAddressInfo.city || isCityChanged) {
        clearFields(updatedAddressInfo, ["state", "zip", "lat", "lng"]);
      } else if (!updatedAddressInfo.state || isStateChanged) {
        clearFields(updatedAddressInfo, ["zip", "lat", "lng"]);
      }
  
      return {
        ...prevContent,
        addressInfo: updatedAddressInfo,
      };
    });
    setStepError((prevError) => ({
      ...prevError,
      [`addressInfo.${field}`]:
        !value || value.trim() === ""
          ? `${field.charAt(0).toUpperCase() + field.slice(1)} is required`
          : "",
    }));
  };

  const onChangeAddress = (updatedAddressInfo) => {
    setStepContent((prev) => ({
      ...prev,
      addressInfo: updatedAddressInfo,
    }));
    setStepError((prevError) => ({
      ...prevError,
      "addressInfo.address":
        !updatedAddressInfo.address ? "Address is required" : "",
      "addressInfo.city": !updatedAddressInfo.city ? "City is required" : "",
      "addressInfo.state": !updatedAddressInfo.state ? "State is required" : "",
      "addressInfo.zip": !updatedAddressInfo.zip ? "Zip is required" : "",
    }));
  }
  return (
    <>
      <Accordion
        square
        disabled={currentStep !== 1 && stepId > currentStep}
        expanded={currentStep === stepId}
        onChange={() => goToStep(stepId)}
        className={`step${stepId}`}
      >
        <AccordionSummary
          aria-controls={`panel${stepId}d-content`}
          id={`panel${stepId}d-header`}
          className={
            currentStep === stepId || currentStep > stepId ? classes.complated : ""
          }
        >
          <Typography className={classes.heading}>
            Create Client Profile
          </Typography>
          <Typography className={classes.secondaryHeading}>Step {stepId}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={`stepContent w-50`}>
            {/* Content Starts */}

            <div className="stepSectionContent">
              <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  name="clientName"
                  id="clientName"
                  label="Client Name *"
                  variant="outlined"
                  placeholder="Client Name"
                  onChange={handleInputChange}
                  value={stepContent?.clientName ? stepContent?.clientName : ""}
                />
                {typeof stepError?.clientName !== "undefined" &&
                  stepError?.clientName !== "" && (
                    <span className="small error color-danger">
                      {stepError?.clientName}
                    </span>
                  )}
              </div>

              <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  name="firstName"
                  id="firstName"
                  label="First Name *"
                  variant="outlined"
                  placeholder="First Name"
                  onChange={handleInputChange}
                  value={stepContent?.firstName ? stepContent?.firstName : ""}
                />
                {typeof stepError?.firstName !== "undefined" &&
                  stepError?.firstName !== "" && (
                    <span className="small error color-danger">
                      {stepError?.firstName}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  name="lastName"
                  id="lastName"
                  label="Last Name *"
                  variant="outlined"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                  value={stepContent?.lastName ? stepContent?.lastName : ""}
                />
                {typeof stepError?.lastName !== "undefined" &&
                  stepError?.lastName !== "" && (
                    <span className="small error color-danger">
                      {stepError?.lastName}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">

                <div className="editor">
                  <Avatar
                    width={"100%"}
                    label="Choose a image for Logo"
                    height={295}
                    onCrop={onCrop}
                    onClose={onClose}
                    src={''}

                  />
                </div>
                <img
                  className="logoImgPreview"
                  src={stepContent?.clientLogo}
                  alt=""
                />

                <p>
                  <small>
                    Logo should not more than 320px width and should only in
                    JPG/JPEG format
                  </small>
                </p>
                {typeof stepError?.clientLogo !== "undefined" &&
                  stepError?.clientLogo !== "" && (
                    <span className="small error color-danger">
                      {stepError?.clientLogo}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">
                <SingleSelect
                  label="Industry Type *"
                  value={
                    stepContent?.industryType ? stepContent?.industryType : ""
                  }
                  className={`field`}
                  name={"industryType"}
                  onChange={handleInputChange}
                  options={[...allIndustryTypes]}
                />
                {typeof stepError?.industryType !== "undefined" &&
                  stepError?.industryType !== "" && (
                    <span className="small error color-danger">
                      {stepError?.industryType}
                    </span>
                  )}
              </div>
              {stepContent?.industryType &&
                stepContent?.industryType === "Other" ? (
                <div className="formFieldwrap">
                  <TextField
                    fullWidth={true}
                    size="small"
                    name="industryTypeOther"
                    id="industryTypeOther"
                    label="Industry Type Name *"
                    variant="outlined"
                    placeholder="Industry Type Name"
                    onChange={handleInputChange}
                    value={
                      stepContent?.industryTypeOther
                        ? stepContent?.industryTypeOther
                        : ""
                    }
                  />
                  {typeof stepError?.industryTypeOther !== "undefined" &&
                    stepError?.industryTypeOther !== "" && (
                      <span className="small error color-danger">
                        {stepError?.industryTypeOther}
                      </span>
                    )}
                </div>
              ) : (
                ""
              )}

              <div className="formFieldwrap">
                <SingleSelect
                  label="Summary Report Frequency"
                  value={
                    stepContent?.summaryReportFrequency
                      ? stepContent?.summaryReportFrequency
                      : ""
                  }
                  className={`field`}
                  name={"summaryReportFrequency"}
                  onChange={handleInputChange}
                  options={[...allFrequency]}
                />
                {typeof stepError?.summaryReportFrequency !== "undefined" &&
                  stepError?.summaryReportFrequency !== "" && (
                    <span className="small error color-danger">
                      {stepError?.summaryReportFrequency}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">
                <SingleSelect
                  fullWidth={true}
                  size="small"
                  name="timeZone"
                  id="timeZone"
                  label="TimeZone *"
                  placeholder="TimeZone"
                  value={stepContent?.timeZone}
                  onChange={handleInputChange}
                  options={selectedValues}
                />
                {typeof stepError?.timeZone !== "undefined" &&
                  stepError?.timeZone !== "" && (
                    <span className="small error color-danger">
                      {stepError?.timeZone}
                    </span>
                  )}
              </div>
              {auth.userProfile.role === "superUser" ?  
                <div className="formFieldwrap">
                  <SingleSelect
                    label="Company *"
                    variant="outlined"
                    name="companyId"
                    id="companyId"
                    placeholder="Company"
                    onChange={handleInputChange}
                    value={stepContent?.companyId ? stepContent?.companyId : ""}
                    options={companyDetails}
                  />
                  {typeof stepError?.companyId !== "undefined" &&
                    stepError?.companyId !== "" && (
                      <span className="d-flex small error color-danger">
                        {stepError?.companyId}
                      </span>
                    )}
                </div>
              : null}
              <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  label="Address *"
                  variant="outlined"
                  placeholder="Address"
                  value={stepContent?.addressInfo?.address || ""}
                  onChange={(e) => handleManualAddressChange('address', e.target.value)}
                />
                {typeof stepError?.address !== "undefined" &&
                  stepError?.address !== "" && (
                    <span className="small error color-danger">
                      {stepError?.address}
                    </span>
                  )}
                </div>
                <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  label="City *"
                  variant="outlined"
                  placeholder="City"
                  disabled={!stepContent?.addressInfo?.address}
                  value={stepContent?.addressInfo?.city || ""}
                  onChange={(e) => handleManualAddressChange('city', e.target.value)}
                />
                {typeof stepError?.city !== "undefined" &&
                  stepError?.city !== "" && (
                    <span className="small error color-danger">
                      {stepError?.city}
                    </span>
                  )}
                </div>
                <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  label="State *"
                  variant="outlined"
                  placeholder="State"
                  disabled={!stepContent?.addressInfo?.city}
                  value={stepContent?.addressInfo?.state || ""}
                  onChange={(e) => handleManualAddressChange('state', e.target.value)}
                />
                {typeof stepError?.state !== "undefined" &&
                  stepError?.state !== "" && (
                    <span className="small error color-danger">
                      {stepError?.state}
                    </span>
                  )}
                </div>
                <div className="formFieldwrap">
               
                <TextField
                  fullWidth={true}
                  size="small"
                  label="Zip *"
                  variant="outlined"
                  placeholder="Zip"
                  disabled={!stepContent?.addressInfo?.state}
                  value={stepContent?.addressInfo?.zip || ""}
                  onChange={(e) => handleManualAddressChange('zip', e.target.value)}
                />
                {typeof stepError?.zip !== "undefined" &&
                  stepError?.zip !== "" && (
                    <span className="small error color-danger">
                      {stepError?.zip}
                    </span>
                  )}
                </div>
              <div className="formFieldwrap">
                <div style={{width:"100%", height:200}}>
                  <AddressByMap onChangeAddress={onChangeAddress} addressInfo={ stepContent?.addressInfo} />
                </div>
              </div>
            </div>
            {/* Content Ends */}
            <div className="buttonsWrap">
              <Button
                type="button"
                color={"primary"}
                variant={"contained"}
                className="flatbutton themeButton mr-15"
                onClick={() => validateAndGoNext()}
                disabled={loading === true ? true : false}
              >
                {loading === true ? "Loading..." : "Save & Proceed"}
              </Button>
              <DialogComponent
                buttonWrapClassName={"d-inline"}
                buttonClassName={"flatbutton"}
                buttonProps={{
                  size: "medium",
                  variant: "contained",
                  disabled: loading === true ? true : false,
                }}
                buttonLabel={"Cancel"}
                ModalTitle={"Are you sure you want to cancel this entry?"}
                ModalContent={<ConfirmCancel onCancelAll={onCancelAll} />}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {dialogOpen && <ClientAddressUpdate open={dialogOpen} onConfirm={handelUpdateAddress} onClose={handelCloseDailog}/>}
    </>
  );
}
export default Step1;
