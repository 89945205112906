/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../hooks/commonContext";
import { Container, Button, TextField, IconButton } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns, ColumnsRes } from "./clientsTableColumns";
import TableToolbar from "../../components/Table/TableToolbar";

import EditIcon from "@material-ui/icons/Edit";
import { useEffect } from "react";
import {
  getClientsList,
  updateClientStatus,
} from "../../services/apiservices/client";
import { useMediaQuery } from "@mui/material";
import ManagePagesCommonCard from "../../components/ResponsiveCardComponent/ManageListPages/ManageListPagesResponsive";
import { getAllEmployee } from "../../services/apiservices/employee";
import AuthContext from "../../hooks/authContext";

const Clients = (props) => {
  const commonContext = useContext(CommonContext);
  const auth = useContext(AuthContext);
  const history = useNavigate();
  const [allClients, setAllClients] = React.useState([]);
  const [searchTerm, setsearchTerm] = React.useState("");
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [expanded, setExpanded] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchClientsList = async () => {
    setIsLoading(true);

    try {
      const [employeeRes, clientRes] = await Promise.all([
        new Promise((resolve) => getAllEmployee(resolve)),
        new Promise((resolve) => getClientsList({}, resolve))
      ]);

      let filterClientData = [];

      if (auth.userProfile.role !== "superUser") {
        const Id = auth.userProfile.role === "companyUser" ? auth.userProfile.id : auth.userProfile.companyId;
        filterClientData = clientRes?.filter((item) => item.companyId === Id);
      } else {
        filterClientData = clientRes;
      }

      const empData = employeeRes?.data?.filter(item => item.role === 'companyUser') || [];

      const setallclientData = filterClientData?.map((rcitem) => {
        const primarycontact = rcitem?.contact?.find(citem => citem?.type === 'primary') || {};
        const companyName = empData.find(item => item.id === rcitem.companyId);

        return {
          ...rcitem,
          clientNumbers: String(rcitem?.clientNumber).padStart(4, '0'),
          clientId: rcitem.id,
          primaryContact: primarycontact.mobileNumber,
          email: primarycontact.email,
          company: companyName ? `${companyName?.firstName} ${companyName?.lastName}` : null,
        };
      });

      let sortedData = sortingFunction(setallclientData)
      setAllClients(sortedData);
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortingFunction = (data) => {
    const result = data?.sort((a, b) => {
      if (a.clientNumbers < b.clientNumbers) return -1;
      if (a.clientNumbers > b.clientNumbers) return 1;

      if (a.email < b.email) return -1;
      if (a.email > b.email) return 1;

      return 0;
    });

    return result;
  };

  useEffect(() => {
    fetchClientsList();
  }, []);

  const redirectToCreateClient = (clientId) => {
    if (typeof clientId !== "undefined" && clientId !== "")
      history("/createClient/" + clientId);
    else history("/createClient");
  };
  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "shiftCount":
        return <>{rcellItem?.shiftData ? [...Object.keys(rcellItem.shiftData)].length : ''}</>;
      case "email":
        return (
          <>
            <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${rcellItem[hcellItem?.id]}`} rel="noopener noreferrer" target="_blank" className="resLinks" style={{ overflowWrap: "anywhere" }}>
              {rcellItem[hcellItem?.id]}
            </a>
          </>
        );
      case "action":
        return (
          <>
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={() => redirectToCreateClient(rcellItem?.clientId)}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const tableRightComponent = () => {
    return (
      (isMobile || isLandscape) ? (
        <div className="d-flex flex-direction-column pl-5">
          <div className="d-flex flex-justify-center mb-15">
            <Button
              className="w-norm clientsummry pv-5 fw-bold"
              style={{ fontSize: "15px", borderRadius: "2px" }}
              size={"small"}
              variant="contained"
              color="primary"
              onClick={() => redirectToCreateClient()}
            >
              Add New Client
            </Button>
          </div>
          <div className="w350">
            <TextField
              fullWidth={true}
              size="small"
              name="searchTerm"
              id="searchTerm"
              label="Search"
              variant="outlined"
              placeholder="Search Client"
              className="input customSearch mb-15"
              onChange={(event) => {
                setsearchTerm(event.target.value);
              }}
              value={searchTerm ? searchTerm : ""}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex groupsearch">
          <div className=" mh-9">
            <TextField
              fullWidth={true}
              size="small"
              name="searchTerm"
              id="searchTerm"
              label=""
              variant="outlined"
              placeholder="Search Client..."
              className="input"
              onChange={(event) => {
                setsearchTerm(event.target.value);
              }}
              value={searchTerm ? searchTerm : ""}
            />
          </div>
          <Button
            className="flatbutton w-norm"
            size={"small"}
            variant="contained"
            color="primary"
            onClick={() => redirectToCreateClient()}
          >
            Add New Client
          </Button>
        </div>
      )
    );
  };

  const OnSwitchChange = (rowitem, newstatus) => {
    fetchClientsList();
    // setIsLoading(true);

    // updateClientStatus(rowitem?.clientId, { status: newstatus })
    //   .then((res) => {
    //     if (res?.status === true) {
    //       fetchClientsList();
    //     } else {
    //       console.log("Error: ", res?.error);
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log("Error updating client:", error);
    //     setIsLoading(false);
    //   });
  };
  const checkRowSearch = (searchTerm, row) => {
    return (
      String(row.clientNumbers)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.email)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.company)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.clientName)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.primaryContact)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase())
    );
  };
  const filteredList =
    searchTerm !== ""
      ? allClients?.filter((item) => checkRowSearch(searchTerm, item))
      : allClients;

  const handleExpandClick = (id) => {
    setExpanded(prevExpanded => (prevExpanded === id ? null : id));
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Clients"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Clients", to: "/" },
          ]}
          BreadCrumbCurrentPageTitle={"Manage Clients"}
          showLastSeen={true}
        />

        <div className={`sectionBox2`}>
          <TableToolbar
            title={isMobile || isLandscape ? "" : "Clients"}
            rightComponent={tableRightComponent()}
          />
          {(!isMobile && !isLandscape) &&
            <ThemeTable
              rows={filteredList}
              headCells={Columns}
              hidePagination={true}
              isLoading={isLoading}
              renderRowCell={renderRowCell}
              // selectedRows={selectedRows}
              OnSwitchChange={OnSwitchChange}
            />
          }
          {(isMobile || isLandscape) && (
            <>
              {filteredList.map((record, index) => {
                const isOpen = expanded === record?.id;
                return (
                  <ManagePagesCommonCard
                    index={index}
                    record={record}
                    isOpen={isOpen}
                    handleExpandClick={handleExpandClick}
                    OnSwitchChange={OnSwitchChange}
                    redirectToDetails={redirectToCreateClient}
                    columns={ColumnsRes}
                    Client={true}
                  />
                );
              })}
            </>
          )}
        </div>
      </Container>
    </>
  );
};
export default Clients;
