import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Drawer,
  Hidden,
  IconButton,
  Button,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SidebarMenu from '../components/SidebarMenu/SidebarMenu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import moment from "moment";
import AuthContext from "../hooks/authContext";
import CommonContext from "../hooks/commonContext";

import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Loader from "../components/AppLoader/Loader";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      // width: drawerWidth,
      flexShrink: 0,
    },
  },
  logoutLink:{
    position:'fixed',
    bottom:0,
    // width: 220,
    left: 0,
    // paddingLeft: 10,
    cursor: 'pointer',
    // backgroundColor:'#343a45',
    "&:hover": {
      backgroundColor:'#00000044',
      cursor: 'pointer',
    },
    '@media (max-width: 767px)': {
      width: 239,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    borderBottomColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: "#343a45",
    minHeight: "auto",
  },
  toolbarmirror: { ...theme.mixins.toolbar, backgroundColor: "transparent" },
  drawerPaper: {
    // width: drawerWidth,
    scrollbarWidth: "none",
    border: 'none',
    boxShadow: '1px 2px 3px rgba(0,0,0,0.4)',
    backgroundColor: "#343a45",
    '@media (max-width: 767px)': {
      width: 240,
    },
  },
  content: {
    flexGrow: 1,
  },
  btnLogout: {
    color:'#fff',
    fontSize:"14px",
    fontWeight:"700",
    '@media (max-width: 767px)': {
      fontSize: "19px",
    },
  },
}));

const DefaultLayouts = (props) => {
  const auth = useContext(AuthContext);
  const [layoutLoader, setLayoutLoader] = useState(false);
  const isExpanded = auth.isExpanded;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation(); 
  const isMobile = useMediaQuery("(max-width:767px)");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const logoutuser = (e) => {
    e.preventDefault();
    auth.logoutuser();
    props.history('/login')
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === '/dashboard' || path === '/profile') {
      setMobileOpen(false);
    }
  }, [location.pathname]);  

  const drawer = (
    <div>
      {auth?.status !== true && <Navigate to="/login" />}
      <div className={classes.toolbar} />
      <div className="d-flex flex-direction-column flex-space-between position-relative">
        {isMobile && (
          <div className="pv-10 ph-15 profile">
            <Link to="/profile" className="d-flex flex-center">
              <div className="mr-10">
                <AccountCircleIcon />
              </div>
              <div className="nameProfile">
                {`${auth.userProfile?.firstName} ${auth.userProfile?.lastName}`}
              </div>
            </Link>
          </div>
        )}
      <SidebarMenu handleDrawerToggle={handleDrawerToggle}/>
         <div className={`${classes.logoutLink} ${isMobile ? "pl-10 testme logoutLink" : isExpanded && !isMobile ? "" : "pl-10 testme logoutLink"}`}>
          <Button
            className={`${classes.btnLogout} ${isExpanded ? "collapsed" : ""}`}
            variant="text"
            onClick={logoutuser}
            startIcon={<ExitToAppIcon />}
          >
            {isExpanded && !isMobile ? null : "Logout"}
          </Button>
        </div>
      </div>
    </div>
  );

  
  return (
    <>
      <CommonContext.Provider
        value={{
          loader: layoutLoader,
          setLoader: setLayoutLoader,
        }}
      >
        <div className={`DefaultContainer `}>
          {layoutLoader === true && <Loader />}
          <div className={classes.root}>
            <CssBaseline />
            {auth?.status === true && (
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon fontSize="large"/>
                  </IconButton>
                  <Header history={props.history} />
                </Toolbar>
              </AppBar>
            )}
            {auth?.status === true && (<>

              <nav className={`${classes.drawer} ${isMobile ? "" : isExpanded ? "" : "drawerWidth"}`} aria-label=" folders">
                <Hidden smUp implementation="css" >
                  <Drawer
                    variant="temporary"
                    anchor={theme.direction === "rtl" ? "right" : "left"}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
              </nav>
            </>
            )}
            <main className={classes.content}>
              <div className={classes.toolbarmirror} />
              <div className="content-wrap MuiContainer-root">{props.children}</div>
            </main>
            <Footer />
          </div>
        </div>
      </CommonContext.Provider>
    </>
  );
};

export default DefaultLayouts;
