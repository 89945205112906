import React, { useEffect } from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { Button, Container, IconButton, TextField } from "@material-ui/core";
import { useNavigate } from "react-router";
import TableToolbar from "../../../components/Table/TableToolbar";
import { getAllEmployee } from "../../../services/apiservices/employee";
import EditIcon from "@material-ui/icons/Edit";
import ThemeTable from "../../../components/Table/ThemeTable";

const ManageCompany = () => {
    const [searchTerm, setsearchTerm] = React.useState("");
    const [companyData, setCompanyData] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();

    const Columns = [
        {
            id: "userName",
            numeric: false,
            disablePadding: true,
            label: "Name",
            isSortable: true,
        },
        {
            id: "email",
            numeric: false,
            disablePadding: true,
            label: "Email",
            isSortable: true,
        },
        {
            id: "phoneNumber",
            numeric: false,
            disablePadding: true,
            label: "Contact",
            isSortable: true,
        },
        {
            id: "role",
            numeric: false,
            disablePadding: true,
            label: "Role",
            isSortable: true,
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Action",
            rowclassName: "text-right",
            className: "text-right pr-30"
        },
    ];


    const fetchCompanyList = async () => {
        setIsLoading(true);
        let companyData = [];
        await getAllEmployee((res) => {
            companyData = res?.data;
        });
        const companyDetails = companyData?.filter((item) => item.role === 'companyUser' && !item.deleted);

        let newData = companyDetails.map((res) => {
            return {
                ...res,
                userName: `${res.firstName} ${res.lastName}`,
            }
        })
        let sortingData = sortingFunction(newData);
        setCompanyData(sortingData);
        setIsLoading(false);
    }

    const sortingFunction = (data) => {
        const result = data?.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;

            if (a.userName < b.userName) return -1;
            if (a.userName > b.userName) return 1;

            return 0;
        });

        return result;
    };


    useEffect(() => {
        fetchCompanyList();
    }, []);


    const redirectToCreateCompany = (companyId) => {
        if (typeof companyId !== "undefined" && companyId !== "")
            navigate("/createCompany/" + companyId);
        else navigate("/createCompany");
    };

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "action":
                return (
                    <>
                        <IconButton
                            color="primary"
                            onClick={() => redirectToCreateCompany(rcellItem?.id)}
                        >
                            <EditIcon />
                        </IconButton>
                    </>
                );

            default:
                return <p>{rcellItem[hcellItem?.id]} </p>;
        }
    }


    const tableRightComponent = () => {
        return (
            <div className="d-flex groupsearch">
                <div className="mh-9">
                    <TextField
                        fullWidth={true}
                        size="small"
                        name="searchTerm"
                        id="searchTerm"
                        label=""
                        variant="outlined"
                        placeholder="Search Company..."
                        className="input"
                        onChange={(event) => {
                            setsearchTerm(event.target.value);
                        }}
                        value={searchTerm ? searchTerm : ""}
                    />
                </div>
                <Button
                    className="flatbutton w-norm"
                    size={"small"}
                    variant="contained"
                    color="primary"
                    onClick={() => redirectToCreateCompany()}
                >
                    Add New Company
                </Button>
            </div>
        )
    };

    const checkRowSearch = (searchTerm, row) => {
        return (
            String(row.userName)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.phoneNumber)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.email)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase())
        );
    };
    const filteredList =
        searchTerm !== ""
            ? companyData?.filter((item) => checkRowSearch(searchTerm, item))
            : companyData;

    return (
        <Container>
            <PageTitle
                pageTitle={"Manage Company"}
                showBreadcrumb={[
                    { label: "Home", to: "/" }
                ]}
                BreadCrumbCurrentPageTitle={"Manage Company"}
                showLastSeen={true}
            />

            <div className="sectionBox">
                <TableToolbar
                    title={"Manage Company"}
                    rightComponent={tableRightComponent()}
                />

                <ThemeTable
                    rows={filteredList}
                    headCells={Columns}
                    hidePagination={false}
                    isLoading={isLoading}
                    renderRowCell={renderRowCell}
                />
            </div>
        </Container>
    )

}

export default ManageCompany;