/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { Container, TextField, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../components/Table/ThemeTable";
import CommonContext from "../../../hooks/commonContext";
import { chagedtime } from "../../../services/helpers";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Columns, ColumnsRes } from "../AlertsTableColumns";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import AlertsDetails from "../AlertsDetails";
import { getalertsList } from "../../../services/apiservices/alerts";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import TableToolbar from "../../../components/Table/TableToolbar";
import { useMediaQuery } from "@mui/material";
import ManageGroupsCommonCard from "../../../components/ResponsiveCardComponent/ManageListPages/ManageGroupPagesResponsive";

export const alertList = [
  { label: "All", value: "all" },
  { label: "Employee - No Check In", value: "nocheckin" },
  { label: "Employee - No Check Out", value: "nocheckout" },
  { label: "Client Added To Emp", value: "emptocl" },
  { label: "Client Removed from Emp", value: "emptoclr" },
  { label: "Employee - Late Check In", value: "checkinlate" },
  { label: "Client - New", value: "newcl" },
];


function TeamAlertPage() {
  const commonContext = useContext(CommonContext);
  const [allAlerts, setAllAlerts] = useState([]);
  const [filterAlerts, setFilterAlerts] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [alertType, setAlertType] = useState("all");
  const isMobile = useMediaQuery('(max-width:767px)');
  const [isLoading, setIsLoading] = React.useState(false);
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

  const setAlertData = () => {
    setFilterAlerts(() =>
      allAlerts.filter(alert => {
        const alertDate = moment(alert.createdOn).format("YYYY-MM-DD");

        if (alertType !== 'all') {
          return alert.type === alertType && alertDate >= startDate && alertDate <= endDate;
        }
        return alertDate >= startDate && alertDate <= endDate;
      })
    );
  };


  useEffect(() => {
    setAlertData();
  }, [allAlerts, startDate, endDate, alertType]);


  const fetchAlertsList = async () => {
    setIsLoading(true);
    await getalertsList(
      async (res) => {
        const formattedAlerts = res.map(alert => ({
          ...alert,
          createdOn: chagedtime(alert?.createdOn)
        }));
        setAllAlerts(formattedAlerts);
        setIsLoading(false);
      },
      (resError) => {
        setIsLoading(false);
      }

    );
  };

  useEffect(() => {
    fetchAlertsList();
  }, []);

  const onChangeInput = async (e) => {
    setAlertType(e.target.value);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue.target.value);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue.target.value);
  };

  const tableRightComponent = () => {
    return (
      <div className="d-flex w320 editEmp issueAlign pl-5 mb-15">
        <div className="mv-7 reportsMargin mb-10">
          <TextField
            name="date"
            label="Start Date"
            value={startDate}
            fullWidth={true}
            size="small"
            variant="outlined"
            className={`customDate`}
            type={"date"}
            inputProps={{
              className: "p-10",
              min: moment().add(-30, "days").format("YYYY-MM-DD"),
              max: moment().add(15, "days").format("YYYY-MM-DD"),
            }}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="mv-7 reportsMargin mb-20">
          <TextField
            name="date"
            label="End Date"
            value={endDate}
            fullWidth={true}
            size="small"
            variant="outlined"
            className={`field customDate`}
            type={"date"}
            inputProps={{
              className: "p-10",
              min: moment().add(-30, "days").format("YYYY-MM-DD"),
              max: moment().add(15, "days").format("YYYY-MM-DD"),
            }}
            onChange={handleEndDateChange}
          />
        </div>
        <div className="alertselect mv-7 reportsMargin mb-20">
          <SingleSelect
            value={alertType}
            fullWidth={true}
            label="Select Type"
            className={`field ${!isMobile && !isLandscape ? "w-185" : "" }`}
            name={"sort"}
            onChange={(e) => onChangeInput(e)}
            options={alertList}
          />
        </div>
      </div>
    );
  };
  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "message":
        return <>{rcellItem?.message?.substring(0, 30) + "..."} </>;
      case "clientname":
        return typeof rcellItem[hcellItem?.id] === 'object' ? '' : rcellItem[hcellItem?.id];
      case "type":
        return (alertList.find((itm) => itm.value === rcellItem[hcellItem?.id]))?.label;
      case "action":
        return (
          <>
            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"iconButton color-primary"}
              buttonProps={{ size: "small" }}
              buttonLabel={<VisibilityIcon />}
              ModalTitle={"Alert Details"}
              ModalContent={<AlertsDetails item={rcellItem} />}
            />
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  return (
    <Container>
      <PageTitle
        pageTitle="Alerts"
        showBreadcrumb={[{ label: "Home", to: "/" }]}
        showLastSeen={true}
      />

      <div className={`sectionBox2`}>
        <Typography variant="body" className="mb-15" style={{fontSize:"20px", marginRight:"67px", fontWeight:"700",color:"#484848", lineHeight:"25px"}}>
         Select your Dates & Alert Type</Typography>
        <TableToolbar
          title=""
          rightComponent={tableRightComponent()}
        />

        {(!isMobile && !isLandscape) ? (
          <ThemeTable
            rows={filterAlerts}
            headCells={Columns}
            isLoading={isLoading}
            hidePagination={false}
            renderRowCell={renderRowCell}
          />
        ) : (
          filterAlerts.map((record, index) => (
            <ManageGroupsCommonCard
              key={record.id}
              index={index}
              record={record}
              columns={ColumnsRes}
              alertList={alertList}
              Alerts={true}
            />
          ))
        )}
      </div>
    </Container>
  );
}

export default TeamAlertPage;
