import { Button, Container } from "@material-ui/core";
import React, { useState } from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import styles from "../Accounts.module.scss"
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { useMediaQuery } from "@mui/material";
import MultiSelect from "../../../components/SelectField/MultiSelect";


const AllowPublicPageShow = ({ isMobile, isLandscape, setPublicPageType, publicPageType }) => {

    const handleToggle = (type) => {
        setPublicPageType(type);
    };

    return (
        <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
            <Button
                variant={publicPageType === 'Yes' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('Yes')}
                style={{
                    backgroundColor:
                        publicPageType === 'Yes' ? '#27ae60' : '#E0E0E0',
                    color: publicPageType === 'Yes' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '5px 0 0 5px',
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    border: 0,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                Yes
            </Button>
            <Button
                variant={
                    publicPageType === 'No' ? 'contained' : 'outlined'
                }
                onClick={() => handleToggle('No')}
                style={{
                    backgroundColor:
                        publicPageType === 'No' ? '#27ae60' : '#E0E0E0',
                    color: publicPageType === 'No' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '0 5px 5px 0',
                    border: 0,
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                No
            </Button>
        </div>
    );
};


const IssuePageSetting = () => {
    const [autoArchiveRequest, setAutoArchiveRequest] = useState();
    const [archiveRequestRole, setArchiveRequestRole] = useState([]);
    const [escalateRequestRole, setEscalateRequestRole] = useState([]);
    const [error, setError] = useState({});
    const autoArchiveOptions = [
        { label: "Not Allowed", value: "notAllowed" },
        { label: "15 Days", value: "15 Days" },
        { label: "30 Days", value: "30 Days" },
        { label: "60 Days", value: "60 Days" },
        { label: "90 Days", value: "90 Days" },
    ];

    const Roles = [
        { label: "ADMIN", value: "admin" },
        { label: "SUPPORT", value: "support" },
    ];
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const [publicPageType, setPublicPageType] = useState('Yes');

    const handleAutoRequestChange = (e) => {
        const data = e.target.value;
        setAutoArchiveRequest(data);
    }

    const handleArchiveRequestRoleChange = (e) => {
        setArchiveRequestRole(e);
    }

    const handleEscalateRequestRoleChange = (e) => {
        setEscalateRequestRole(e);
    }
    const validFields = () => {
        const errormessages = {};
        let isValid = true;

        if (!autoArchiveRequest) {
            isValid = false;
            errormessages.autoArchiveRequest = "Auto Archive Request is Required";
        }
        if (!archiveRequestRole.length) {
            isValid = false;
            errormessages.archiveRequestRole = "Archive Request Role is Required";
        }
        if (!escalateRequestRole.length) {
            isValid = false;
            errormessages.escalateRequestRole = "Escalate Request Role is Required";
        }

        setError(errormessages);
        return isValid;
    };

    const handelSaveData = () => {
        if (!validFields()) {
            return;
        } else {
            console.log("Data save", escalateRequestRole, archiveRequestRole, autoArchiveRequest)
        }
    }

    return (
        <Container>
            <PageTitle
                pageTitle={`Issue Settings`}
                showBreadcrumb={[
                    { label: "Home", to: "/" },
                    { label: "Settings", to: "/settings" },
                ]}
                BreadCrumbCurrentPageTitle={"Issue"}
                showLastSeen={true}
            />
            <div className="sectionBox">
                <div className={`${isMobile ? "w-100" : "w-100 d-flex"}`}>
                    <div className={styles.widths}>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>Auto Archive Request</div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <SingleSelect
                                    value={autoArchiveRequest}
                                    name={"autoArchiveRequest"}
                                    label={"Auto Archive Request"}
                                    onChange={handleAutoRequestChange}
                                    options={autoArchiveOptions}
                                />
                                {error.autoArchiveRequest && (
                                    <span className="d-flex small error color-danger">{error.autoArchiveRequest}</span>
                                )}
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>Archive Request Role</div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <MultiSelect
                                    value={archiveRequestRole}
                                    name={"archiveRequestRole"}
                                    label={"Archive Request Role"}
                                    onChange={(e) => handleArchiveRequestRoleChange(e)}
                                    options={Roles}
                                    selectAllOption={true}
                                />
                                {error.archiveRequestRole && (
                                    <span className="d-flex small error color-danger">{error.archiveRequestRole}</span>
                                )}
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>Escalate Request Role</div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <MultiSelect
                                    value={escalateRequestRole}
                                    name={"escalateRequestRole"}
                                    label={"Escalate Request Role"}
                                    onChange={(e) => handleEscalateRequestRoleChange(e)}
                                    options={Roles}
                                    selectAllOption={true}
                                />
                                {error.escalateRequestRole && (
                                    <span className="d-flex small error color-danger">{error.escalateRequestRole}</span>
                                )}
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>Enable Public Page View</div>
                            <AllowPublicPageShow
                                isMobile={isMobile}
                                isLandscape={isLandscape}
                                setPublicPageType={setPublicPageType}
                                publicPageType={publicPageType}
                            />
                        </div>
                    </div>
                </div>
                <div className="mv-15">
                    <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={handelSaveData}>Save</Button>
                    <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }}>Cancel</Button>
                    <Button variant="contained" color="secondary" style={{ marginLeft: 10, boxShadow: 'none' }}>Reset</Button>
                </div>
            </div>
        </Container>
    )
}

export default IssuePageSetting;