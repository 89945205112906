import React, { useEffect, useState } from "react";
import {alertList} from './Team/TeamAlertPage';
const AlertsDetails = (props) => {
  const [itemdata, setItemdata] = useState({});

  useEffect(() => {
    setItemdata(props.item);
  }, [props.item]);
  //const emplist = itemdata?.Employee ? itemdata?.Employee?.map((eitem) => eitem.name) : [];
  //const empliststring = emplist.join(', ')
  return (
    <div className="pb-15 ph-15">
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Date : </span> 
        <span className="updateDialogLabel">{itemdata?.createdOn}</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Title : </span> 
        <span className="updateDialogLabel">{itemdata?.title}</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Type : </span>
        <span className="updateDialogLabel">{(alertList.find((itm) => itm.value === itemdata?.type))?.label}</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Client : </span> 
        <span className="updateDialogLabel">{itemdata?.clientname}</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName"> Message : </span> 
        <span className="updateDialogLabel">{itemdata?.description}</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Employees : </span> 
        <span className="updateDialogLabel">{itemdata?.empname}</span>
      </div>
    </div>
  );
};
export default AlertsDetails;
