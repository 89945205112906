const Columns = [
    {
      id: "switch",
      numeric: false,
      disablePadding: false,
      label: "Status",
      isSortable: true,
    },
    {
        id: "clientNumbers",
        numeric: false,
        disablePadding: true,
        label: "Client ID",
        isSortable: true,
    },
    {
        id: "shiftCount",
        numeric: false,
        disablePadding: true,
        label: "Shift Count",
        isSortable: true,
    },
    {
        id: "clientName",
        numeric: false,
        disablePadding: true,
        label: "Client Name",
        isSortable: true,
    },
    {
        id: "primaryContact",
        numeric: false,
        disablePadding: true,
        label: "Primary Contact",
        isSortable: true,
    },
    {
        id: "company",
        numeric: false,
        disablePadding: true,
        label: "Company",
        isSortable: true,
    },
    {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        isSortable: true,
    },
    { id: "action", numeric: false, disablePadding: true, label: "Action",rowclassName:"text-right", className:"text-right pr-30" },
];

const ColumnsRes = [
    {
        id: "clientName",
        numeric: false,
        disablePadding: true,
        label: "Client Name",
        isSortable: true,
    },
    {
        id: "shiftCount",
        numeric: false,
        disablePadding: true,
        label: "Shift Count",
        isSortable: true,
    },
    {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        isSortable: true,
    },
    {
        id: "primaryContact",
        numeric: false,
        disablePadding: true,
        label: "Primary Contact",
        isSortable: true,
        isCollapsible: true,
    },
    {
        id: "company",
        numeric: false,
        disablePadding: true,
        label: "Company",
        isSortable: true,
        isCollapsible: true,
    },
    {
        id: "switch",
        numeric: false,
        disablePadding: false,
        label: "Status",
        isSortable: true,
        isCollapsible: true,
    },
];

export { Columns, ColumnsRes };
  