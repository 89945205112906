import axiosInstance from "../axios";
import { getLoginToken } from "../storage";
import { BaseUrl, COLLECTIONS } from "../config";
import { collection, query, where, getDocs, documentId, addDoc, serverTimestamp, doc, setDoc} from "firebase/firestore"; 
import { firebase } from "../firebase-config"; 
import { getAuth } from "firebase/auth";
import { generateP } from "../helpers";

const defaultHeaders = {
  "Content-Type": "application/json",
  withCredentials: true,
};

let userCollection = COLLECTIONS.USERS;

/**
 * Unique validate user by email and phone number
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function validateUniqueUser(formData, onSuccess, onError) {
  let responseObj = {};
  let isValid = true;

  // Check for email
  if (formData.email && formData.email !== "") {
    const userRef = collection(firebase, userCollection);
    const emailQuery = query(userRef, where("email", "==", formData.email));
    const snapshot = await getDocs(emailQuery);
    let userInfo = {};

    snapshot.forEach((dd) => {
      userInfo = dd.data();
    });

    if (
      (!snapshot.empty && !formData.userId) ||
      (!snapshot.empty && formData.userId && formData.userId !== userInfo.userId)
    ) {
      responseObj = { ...responseObj, email: "Email Already Registered" };
      isValid = false;
    }
  }

  if (formData.mobile && formData.mobile !== "") {
    const userRef = collection(firebase, userCollection);
    const mobileQuery = query(userRef, where("phoneNumber", "==", formData.mobile));
    const snapshotMobile = await getDocs(mobileQuery);
    let userInfo = {};

    snapshotMobile.forEach((dd) => {
      userInfo = dd.data();
    });

    if (
      (!snapshotMobile.empty && !formData.userId) ||
      (!snapshotMobile.empty && formData.userId && formData.userId !== userInfo.userId)
    ) {
      isValid = false;
      responseObj = {
        ...responseObj,
        mobile: "Mobile Number Already Registered",
        phoneNumber: "Mobile Number Already Registered",
      };
    }
  }

  onSuccess({ status: isValid, data: responseObj });
}

/**
 * Get list of client users (Not used after restructured)
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getClientList(formData, onSuccess, onError) {
  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  try {
    const res = await axiosInstance.get(`${BaseUrl}/clients`, {
      params: { ...formData },
      headers: { ...defaultHeaders, ...authHeaders },
    });
    onSuccess && onSuccess(res);
  } catch (error) {
    onError && onError(error);
  }
}


/**
 * Get list of users (Not used after restructured)
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getUserListBasedOnIds(userIDs, onSuccess, onError) {
  const chunk = (arr, chunkSize) => {
    const R = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  };

  const usersNew = [];
  try {
    const userChunks = chunk(userIDs, 10);
    for (const chunkIds of userChunks) {
      const colRef = collection(firebase, userCollection); 
      const q = query(colRef, where(documentId(), 'in', chunkIds));
      const snap = await getDocs(q);
      
      snap.forEach((doc) => {
        const userData = { ...doc.data(), id: doc.id };
        usersNew.push(userData);
      });
    }
    onSuccess && onSuccess(usersNew);
  } catch (error) {
    console.error('Error getting documents: ', error);
    onError && onError(error);
  }
}

export async function saveOrUpdateUserData(formData, id) {
  const auth = getAuth();
  const currentUserId = auth.currentUser?.uid;

  try {
    if (!currentUserId) {
      throw new Error("No authenticated user found");
    }

    if (id) {
      const userDocRef = doc(firebase, COLLECTIONS.USERS, id);
      const updateData = {
        ...formData,
        updatedBy: currentUserId,
        updatedAt: serverTimestamp(),
      };

      await setDoc(userDocRef, updateData, { merge: true });
      return { status: true, data: { ...formData, id } };

    } else {
      const password = generateP();
      const newCompanyData = {
        ...formData,
        status: true,
        password: password,
        confirmPassword: password,
        createdBy: currentUserId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      const docRef = await addDoc(collection(firebase, COLLECTIONS.USERS), newCompanyData);
      return { status: true, data: { ...formData, id: docRef.id } };
    }
  } catch (error) {
    return { status: false, error: error.message || "An unknown error occurred" };
  }
}

export async function deleteUsers(id) {
  try {
    const auth = getAuth();
    const currentUserId = auth.currentUser?.uid;

    if (!currentUserId) {
      throw new Error("No authenticated user found.");
    }

    const userDocRef = doc(firebase, COLLECTIONS.USERS, id);

    const updateData = {
      deleted: true,
      status: false,
      updatedBy: currentUserId,
      deletedBy: currentUserId,
      deletedAt: serverTimestamp(),
    };

    await setDoc(userDocRef, updateData, { merge: true });

    return { status: true };
  } catch (error) {
    return {
      status: false,
      error: error.message || "An unknown error occurred while deleting the user.",
    };
  }
}